<template>
  <q-dialog v-model="show">
    <q-card style="width: 1800px; max-width: 95%">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h4 q-mt-sm q-mb-xs">
          {{ getRes(title) }}
        </div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup no-caps />
      </q-card-section>

      <q-card-section>
        <pro-form-render
          mode="View"
          :code="WorkflowCode"
          :form-data-promise="formDataPromise()"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";
export default {
  name: "AuditTrailRecordDialog",
  components: { ProFormRender },
  props: {
    title: {
      /**
       * @param {string} i18n key string
       */
      type: String,
      default: "Form.Section.Record",
    },
  },
  data() {
    return {
      show: false,
      WorkflowCode: "Supplier_SupplierRegInfo",
      SupplierCompanyInfo: {},
    };
  },
  methods: {
    showInfo(SupplierCompanyInfo) {
      this.SupplierCompanyInfo = SupplierCompanyInfo;
      this.show = true;
    },
    formDataPromise() {
      return new Promise((resolve) => {
        let obj = this.SupplierCompanyInfo;
        resolve(obj);
      });
    },
  },
};
</script>
