<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs
      :breadcrumbs="breadcrumbs"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <pro-report
      title="CustMenu.Title.SearchSupplier"
      :columns="columns"
      v-model="fullList"
      row-key="id"
      auto-search
      selectable
      :loading="loading"
      :show-download-to-excel="true"
      file-name="Supplier_List_"
      @row-select-event="rowClickEvent"
    >
      <template #body-cell-status="{ col, value }">
        <q-td style="width: 200px" :class="col.__tdClass" :style="col.style">
          <span
            :class="value === getRes('Document.Status.Inactive') ? 'red' : ''"
          >
            {{ value }}
          </span>
        </q-td>
      </template>
      <template v-slot:selectedDialog>
        <SupplierRegistrationPopupWindow
          ref="audit"
        ></SupplierRegistrationPopupWindow>
      </template>
    </pro-report>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import SupplierRegistrationPopupWindow from "@/views/modules/supplierList/SupplierRegistrationPopupWindow";
import ProReport from "@/components/PROSmart/Report/ProReport";

export default {
  name: "SupplierList",
  components: {
    ProDocumentBreadcrumbs,
    SupplierRegistrationPopupWindow,
    ProReport,
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: "Form.Section.SupplierList",
          icon: "PROSmart-Account",
        },
      ],
      loading: true,
      selected: [],
      isShowItem: true,
      SupplierCompanyInfo: {},
      columns: [
        {
          name: "supplierCode",
          label: this.getRes("Form.Section.SupplierCode"),
          field: "supplierCode",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "string",
        },
        {
          name: "supplierLoginId",
          label: this.getRes("Form.Field.SupplierLoginId"),
          field: "supplierLoginId",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "supplierCompanyName",
          label: this.getRes("Form.Field.SupplierCompanyName"),
          field: "supplierCompanyName",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "email",
          label: this.getRes("Form.Field.Email"),
          field: "email",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "status",
          label: this.getRes("Form.Field.Statu"),
          field: "status",
          align: "left",
          searchable: true,
          sortable: true,
          type: "chip",
          list: [
            {
              label: this.getRes("Document.Status.Registering"),
              value: this.getRes("Document.Status.Registering"),
            },
            {
              label: this.getRes("Document.Status.PendingForApproval"),
              value: this.getRes("Document.Status.PendingForApproval"),
            },
            {
              label: this.getRes("Document.Status.Approved"),
              value: this.getRes("Document.Status.Approved"),
            },
            {
              label: this.getRes("Document.Status.Inactive"),
              value: this.getRes("Document.Status.Inactive"),
            },
            {
              label: this.getRes("Document.Status.Purged"),
              value: this.getRes("Document.Status.Purged"),
            },
          ],
        },
      ],
      fullList: [],
    };
  },
  methods: {
    rowClickEvent(row) {
      this.$proSmart.supplierUi
        .getSupplierCompanyInfoByProcurer(this, row.supplierCode)
        .then((b) => {
          b.cmpyNameEng = row.cmpyNameEng;
          b.cmpyNameChi = row.cmpyNameChi;
          b.emailAddress = row.email;

          this.$refs.audit.showInfo(b);
        });
    },
    multilingualUserName(eName, cName, tName) {
      let language = localStorage.getItem("Language");
      if (language !== undefined) {
        switch (language) {
          case "English":
            return eName;
          case "Chinese":
            return cName;
          case "TraditionalChinese":
            return tName;
          default:
            return eName;
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$proSmart.supplierUi.getAllSupplier(this).then((b) => {
      this.fullList = b.map((item) => {
        return {
          id: item.id,
          supplierCode: item.hcmUserId,
          supplierLoginId: item.supplierLoginId,
          supplierCompanyName: this.multilingualUserName(
            item.cmpyNameEng,
            item.cmpyNameChi
          ),
          cmpyNameEng: item.cmpyNameEng,
          cmpyNameChi: item.cmpyNameChi,
          email: item.emailAddress,
          status: this.getRes(`Document.Status.${item.status}`),
        };
      });
      this.loading = false;
    });
  },
};
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
</style>
